import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const Adaptacje = () => {
  return (
    <Layout
      title={"Adaptacje"}
      image={
        <StaticImage
          src={"../assets/images/adaptations.jpg"}
          alt={"budynek"}
          placeholder={"blurred"}
          className={"site-banner"}
          style={{ position: "absolute" }}
        />
      }
    >
      <div>
        Każdy projekt gotowy - bez względu na to przez kogo jest oferowany -
        wymaga tzw. adaptacji. Adaptacja projektu polega na wprowadzeniu
        możliwości wykorzystania tego projektu na konkretnej działce i
        dostosowania go do lokalnych warunków, tj. położenia względem stron
        świata, ukształtowania terenu, sprawdzenia fundamentów w odniesieniu do
        nośności podłoża gruntowego, strefy klimatycznej i głębokości
        przemarzania, uzyskania niezbędnych warunków technicznych dla
        infrastruktury technicznej (woda, kanalizacja, energia elektryczna,
        wjazd na działkę) oraz uzgodnienia w Zespole Uzgodnień Dokumentacji
        Projektowej działającym przy odpowiednim Urzędzie Miejskim lub
        Starostwie Powiatowym.
      </div>
      <div>
        Podstawowa adaptacja może się ograniczać do dostosowania fundamentów do
        miejscowych warunków gruntowych. Jednak często z warunków położenia i
        charakteru działki oraz potrzeb Inwestora wynikają zmiany w projekcie
        gotowym - np. inne rozmieszczenie otworów okiennych i drzwiowych,
        poszerzenie i wydłużenie budynku lub jego zwężenie, dobudowa garażu,
        nadbudowa lub rozbudowa części budynku w parterze lub w poddaszu
        budynku, zmiana kąta nachylenia połaci dachu, które wprowadzone są w
        ramach adaptacji projektu.
      </div>
      <div>
        Adaptacji, w tym również zmian w projekcie gotowym, możemy dokonać na
        zlecenie Inwestora, gdyż w naszym Biurze są uprawnieni projektanci -
        członkowie Izb Inżynierów Budownictwa i Architektów, którzy zgodnie z
        obowiązującymi przepisami podpisując zaadoptowany projekt budowlany
        stają się autorami projektu budowlanego i biorą odpowiedzialność za
        prawidłowość i kompetentność przekazywanego projektu.
      </div>
    </Layout>
  )
}

export default Adaptacje
